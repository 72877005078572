import { ReactElement, useState, ReactNode, useRef, useMemo, useEffect, createRef } from 'react'
import '@web/styles/Collapse.less'
import clsx from 'clsx'
import ResizeObserver from 'resize-observer-polyfill'

interface CollapseProps {
  /**
   * Id of element
   */
  id?: string
  /**
   * Indicates the collapse is collapsed
   */
  isCollapsed: boolean
  /**
   * Additional class name(s) to give to the containing element
   */
  className?: string
  /**
   * Override for the content height
   */
  contentHeight?: number
  /**
   * Children of element
   */
  children: ReactNode
  /**
   * auto scroll to the top of div when collapsed
   */
  scrollOnCollapse?: boolean
}

/**
 * Collapse component that holds the collapsable content.
 *
 * For easy collapse state management use `useToggle`.
 */
function Collapse ({
  id,
  isCollapsed,
  className,
  contentHeight,
  children,
  scrollOnCollapse = false
}: CollapseProps): ReactElement {
  const refDiv = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState('0')
  const [scrollable, setScrollable] = useState(false)
  const collapseElement = createRef<HTMLDivElement>()
  const containerClassname = useMemo(() => clsx('react-collapseable-content', scrollable && 'react-collapseable-content-scroll', className), [className, height, scrollable])
  useEffect(() => {
    if (isCollapsed || refDiv.current === null) {
      return undefined
    }

    const resizeObserver = new ResizeObserver((el) => {
      setScrollable(el[0].contentRect.height > 450)
    })

    resizeObserver.observe(refDiv.current)
    return () => {
      resizeObserver.disconnect()
    }
  }, [height, isCollapsed])

  useEffect(() => {
    if (isCollapsed) {
      if (scrollOnCollapse) {
        collapseElement.current?.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
      setHeight('0')
    } else { setHeight('auto') }
  }, [isCollapsed])

  return (
    <div
      id={id}
      style={{ height, maxHeight: contentHeight }}
      className={containerClassname}
      ref={collapseElement}
    >
      <div ref={refDiv}>{children}</div>
    </div>
  )
}

export { Collapse, CollapseProps }
